import React, { useState } from 'react';
import * as AppActions from "../../redux/actions/appAction";
import { useDispatch } from "react-redux";
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import './style.css';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import moment from 'moment';
import { VStack, Modal, View, Text, Button, Center } from 'native-base';
import { textStyle } from 'src/styles/textStyle';
import { getText } from 'src/utils/i18n';

export default function DateRangePickerModal() {
  const [calendarModalVisible, setCalendarModalVisible] = React.useState(false);
  const dispatch = useDispatch();

  const showCalendarModal = () => setCalendarModalVisible(true);
  const hideCalendarModal = () => setCalendarModalVisible(false);

  const onSelectedValueChanged = (val: any) => {
    setSelectedDayRange(val)

    if (val.from && val.to) {
      var fromDate = moment([val.from.year, val.from.month - 1, val.from.day]);
      var toDate = moment([val.to.year, val.to.month - 1, val.to.day]);
      dispatch(AppActions.setDateRange({
        from: fromDate.unix(),
        to: toDate.unix()
      }));
    }
  }

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null
  });

  const getFormattedDateRange = () => {
    if (selectedDayRange.from && selectedDayRange.to) {
      return `${selectedDayRange.from.year}/${selectedDayRange.from.month}/${selectedDayRange.from.day} - ${selectedDayRange.to.year}/${selectedDayRange.to.month}/${selectedDayRange.to.day}`;
    }
    return "Select Date Range";
  }

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  return (
    <View>
      <Modal
        size={"xl"}
        isOpen={calendarModalVisible}
        onClose={hideCalendarModal}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Select Date Range</Modal.Header>
          <Modal.Body>
            <Center>
              <Calendar
                value={selectedDayRange}
                calendarClassName="responsive-calendar"
                onChange={onSelectedValueChanged}
                shouldHighlightWeekends
              />
            </Center>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button variant="ghost" colorScheme="blueGray" onPress={hideCalendarModal}>
                Cancel
              </Button>
              <Button
                onPress={hideCalendarModal}>Save</Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <VStack space={3}>
        <Text style={textStyle.subSectionText}>{getText('chooseDateRange')}</Text>
        <Button
          variant={"outline"}
          onPress={showCalendarModal}>{
            getFormattedDateRange()
          }</Button>
      </VStack>
    </View>

  );
};