import React, { useState } from 'react';
import { Text } from 'react-native';
import * as AppActions from "../../redux/actions/appAction";
import { useDispatch } from "react-redux";
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import './style.css';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import moment from 'moment';
import { VStack } from 'native-base';
import { textStyle } from 'src/styles/textStyle';
import { getText } from 'src/utils/i18n';

export default function DateRangePicker() {
  const dispatch = useDispatch();

  const onSelectedValueChanged = (val: any) => {
    setSelectedDayRange(val)

    if (val.from && val.to) {
      var fromDate = moment([val.from.year, val.from.month - 1, val.from.day]);
      var toDate = moment([val.to.year, val.to.month - 1, val.to.day]);
      dispatch(AppActions.setDateRange({
        from: fromDate.unix(),
        to: toDate.unix()
      }));
    }
  }

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null
  });

  return (
    <VStack space={3}>
      <Text style={textStyle.subSectionText}>{getText('chooseDateRange')}</Text>
      <Calendar
        value={selectedDayRange}
        calendarClassName="responsive-calendar"
        onChange={onSelectedValueChanged}
        shouldHighlightWeekends
      />
    </VStack>
  );
};